import { showToast, Field, CellGroup, Button, showDialog, Popup } from 'vant';
import 'vant/es/toast/style';
import axios from 'axios';
export default {
  data() {
    return {
      price: null,
      message: null,
      dialogshow: false,
      errshow: false,
      err_message: null
    };
  },
  methods: {
    formatPrice() {
      return '¥' + (this.goods.price / 100).toFixed(2);
    },
    pay() {
      let price = this.price;
      if (!price) {
        console.log("请输入金额");
        showToast('请输入正确金额~');
        return;
      }
      let zhengshu = price % 50;
      if (zhengshu == 0) {
        this.dialogshow = true;
        console.log("是整数啊");
      } else {
        console.log("不是整数啊");
        this.quepay();
      }
    },
    quepay() {
      var that = this;
      let price = this.price;
      // console.log("确认支付1111111")
      // console.log(price)
      // return
      if (!price) {
        console.log("请输入金额");
        showToast('请输入正确金额~');
        return;
      }
      let url = "https://www.luojiaming.vip/tripartite/xuchengjia/pay?total_fee=" + String(price);
      axios.get(url).then(function (response) {
        console.log(response);
        let return_code = response.data.return_code;
        if (return_code == "err") {
          that.err_message = response.data.msg;
          that.errshow = true;
          return;
        } else {
          let qrcode = response.data.data.qrcode;
          window.open(qrcode, "_self");
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    cancellation() {
      console.log("确认支付弹框取消");
    }
  }
};